.mainScreenBody {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownMenu {
  z-index: 1000 !important;
  /* background-color: #30324e !important; */
  color: red !important;
}
.dropdownMenuNight {
  z-index: 1000 !important;
  background-color: #30324e !important;
  color: red !important;
}

.dropdown_comp * {
  z-index: 1000 !important;
  color: white !important;
  background-color: #30324e;
}

.dropdown_comp_white * {
  z-index: 100 !important;
}

.icon_comp * {
  color: white !important;
  fill: white;
}
