.btn {
  padding: 0.7rem 1.2rem;
  background-color: rgb(82, 80, 190);
  color: rgb(255, 255, 255);
  font-weight: 600;
  border: none;
  border-radius: 0.3rem;
  max-width: 300px;
}

.btn:hover {
  cursor: pointer;
}
