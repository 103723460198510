.folders-body {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.folders-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
}

.folders-dropdown {
  max-width: 300px;
  padding: 0.5rem 0rem;
}

.folders-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  text-align: left;
}

.folders-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 300px;
}

.workspacesAndBoardsBody {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_comp * {
  color: white !important;
  background-color: #30324e;
}

.customTableHeaderCell {
  width: 100% !important;
}
