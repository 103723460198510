.sync-enabled {
  display: flex;
  padding: 0rem 1rem;
  flex-direction: column;
  max-width: 500px;
}

.db-body {
  min-width: 400px;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.db-sync-content {
}

.dropdown-width {
  margin-top: 0.75rem;
  max-width: 250px;
  height: 35px;
}

.form-style {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-style input {
  padding: 0.5rem;
}
.form-style label {
  margin-top: 0.5rem;
}

.submit-style {
  font-family: var(--font-family);
  width: 165px;
  height: 40px;
  padding: 0.75rem 1.5rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  color: var(--primary-text-color);
  transition: 400ms;
}

.submit-style:hover {
  cursor: pointer;
  background-color: var(--btn-hover-color);
  transition: 400ms;
}

.sync-enabled-checkbox {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 1rem;
  width: fit-content;
}

.sync-enabled-checkbox:hover {
  cursor: pointer;
}

.label-style {
  display: flex;
  gap: 0.4rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.form-inline {
  gap: 2.5rem;
  display: flex;
}

.form-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-port {
  max-width: 20%;
}
.input-host {
  width: 100%;
}

.validation-message {
  padding-top: 1rem;
  padding-left: 1rem;
  color: rgb(216, 28, 28);
}

.validation-message-success {
  padding-top: 1rem;
  padding-left: 1rem;
  color: rgb(94, 176, 39);
}

.db-btns-inline {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.btn-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  scale: 2.5;
}

.db-save-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.3rem;
  margin-top: 1.5rem;
}

.sync-enabled-checkbox input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid var(--border-color);
  outline: none;
  cursor: pointer;
}

.sync-enabled-checkbox-checked {
  background-color: var(--background-color);
  position: relative;
}

.sync-enabled-checkbox-checked::before {
  content: '\2713';
  font-size: 1.4em;
  color: var(--primary-text-color);
  position: absolute;
  left: 6px;
}
