.App {
  text-align: center;
}

.layout-light {
  color: #323338;
  --primary-text-color: #323338;
  --background-color: #ffffff;
  --btn-hover-color: #d1d1d1;
  --secondary-background-color: #fffffb;
  --border-color: #c3c6d4;
  --box-shadow-color: rgba(0, 0, 0, 0.4);
}

.layout-dark {
  color: #d5d8df;
  --primary-text-color: #d5d8df;
  --background-color: #181b34;
  --btn-hover-color: #2c305b;
  --secondary-background-color: #30324e;
  --border-color: #797e93;
  --box-shadow-color: rgba(250, 250, 250, 0.25);
}

.layout-black {
  color: #eeeeee;
  --primary-text-color: #eeeeee;
  --background-color: #111111;
  --btn-hover-color: #313131;
  --secondary-background-color: #2c2c2c;
  --border-color: #8d8d8d;
  --box-shadow-color: rgba(250, 250, 250, 0.25);
}
